//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;
    background-color: $color-main;

    li {
        position: relative;

        &:last-child { 
            a, button {
                &:after {
                    display: none;
                }
            }

        }

        a, button, div {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0;
            padding: 17px 30px;
            background-color: transparent;
            border: none;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $color-second;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 25%;
                background-color: $color-second;
                border-radius: 50px;
                transform: translateY(-50%);
                opacity: 1;
                transition: all $duration ease-in-out;
            }

            svg {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                fill: $color-second;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-second;
                background-color: $color-bg--transparent;
                text-decoration: underline;

                svg {
                    fill: $color-second;
                }
            }


        }

        &:last-child a, 
        &:last-child button {
            border-right: 0;
        }

    }

    .share-list {
        position: absolute;
        top: 10px;
        left: 30px;
        display: none;
    }

    &.shared-on {
 
        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            column-gap: 10px;

            li {
                margin-right: 0;
                border-right: 0;

                div::after {
                    display: none;
                }
            }

            a, button, div {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius--round;
                opacity: 1;
                transition: all $duration ease-in-out;

                &:hover, &:focus {
                    background-color: $color-dark;
                    border: 0;
                    transition: all $duration ease-in-out;

                    svg {
                        fill: $color-white;
                        transition: all $duration ease-in-out;
                    }

                }

                svg {
                    margin: 0;
                    fill: $color-text;
                    transform: scale(1.1);
                    transition: all $duration ease-in-out;
                }

                &.close {
                    svg {
                        // stroke: $color-text;
                        fill: $color-text;
                        transform: scale(1.5);
                    }

                    &:hover, &:focus {
                        svg {
                            fill: $color-white;
                            // stroke: $color-white;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-gray;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}

section[data-cpt="maps"] .actions-post {
    margin-top: 0;
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-post {
        &.shared-on {
            margin: 60px auto 20px;

            .share-list {
                display: flex;
                position: absolute;
                top: -50px;
                left: -120px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
            }

            .action-share {
                opacity: 1;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .actions-post {
        > li {
            > a, button {
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 20px;
                padding: 0;
                background: $color-white;
                border-radius: $border-radius;

                span {
                    display: none;
                }

                svg {
                    fill: $color-text;
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    background: $color-main;

                    svg {
                        fill: $color-second;
                    }
                }
            }

            a::after, button::after {
                display: none;
            }
        }

        &.shared-on {
            .share-list {
                left:-185px;

                button {
                    margin: 0;

                    > svg {
                        fill: $color-text;
                    }
                }
            }
        }

    }
    
}
