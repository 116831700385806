.bloc-demarche {
    margin-top: 20px;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: $shadow;

    .accordion--demarche {
        margin: 0;
    }

    &--alone {
        border: 0;
        box-shadow: none;
        
        .bloc-demarche__content {
            padding: 0;
        }
    }

    &__title {
        @extend .accordion__title--question;
        padding: 20px 70px 20px 105px;
        background: url(/wp-content/themes/noyau/assets/images/ic-comarquage.png) no-repeat $color-main;
        background-position: 22px center;
        background-size: 60px auto;
    }

    details {
        summary > svg {
            position: absolute;
            z-index: 2;
            margin: auto;
            right: 30px;
            top: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
            fill: $color-second;
            transform: rotate(0);
            transition: $duration ease;
        }
    }

    &__content {
        display: block;
        padding: 30px;
        overflow-x: hidden;

        #comarquage {
            margin-top: 5px;

            #co-bar, #co-top, .co-breadcrumb, .news-cat, #co-footer {
                display: none !important;
            }

            #co-page, #co-page.noeud {
                margin-top: 0;
            }
        }
    }

}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-demarche {
        &__content {
            padding: 20px;
        }

        &__title {
            padding: 20px 60px 20px 110px;
        }

        details summary > svg {
            right: 20px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-demarche {
        &__title {
            padding: 70px 70px 15px 15px;
            background-position: 15px 15px;
        }

        #comarquage #co-page .tool-slide button {
            margin-left: 0px;
        }
    }

}
