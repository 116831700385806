.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 35px 0;
    background: $color-dark;

    .return {
        a {
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-white;
            transition: all $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-white;
                transition: all $duration;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 0;
                height: 2px;
                background-color: $color-white;
                transition: width ease-in-out $duration;
            }
    
            &:hover, &:focus {
                color: $color-white;
    
                &::after {
                    width: 101%;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
 }
 
 .post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }
 
    .return {
        a {
            align-items: center;
        }
    }
 
    .prev, .next {
        min-width: 115px;

        .navigation_link {
            color: $color-white;
 
            // Recup style ~ ligne 9
            a {
                position: relative;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                color: $color-white;
                transition: all $duration;
                width: fit-content;
            }
        }
    }
 
    .prev {
        .navigation_link {
            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-white;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
 
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-white;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
 
    .next {
        .navigation_link {
            display: flex;
            justify-content: flex-end;
            
            a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-white;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
 
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-white;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
 
    .prev, .next {
        &:hover, &:focus, &:focus-within {
 
            .navigation_link {
                color: $color-white;
 
                svg {
                    fill: $color-white;
                }
 
                a {
                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
 
    .prev {
        &:hover, &:focus, &:focus-within {
 
            .navigation_link {
                a::after {
                    width: 101%;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
 
    .next {
        &:hover, &:focus, &:focus-within {
 
            .navigation_link {
                a::before {
                    width: 101%;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
 }
 
 
 //======================================================================================================
 // Responsive
 //======================================================================================================
 
 // 640
 @media screen and (max-width: $small) {
 
    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
 }