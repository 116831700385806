.nav-main {
    position: relative;
    z-index: 11;

    // For Responsive
    &__close {
        display: none;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0 0 0 auto;
        padding: 10px;
        background-color: transparent;
        border: 2px solid $color-white;
        border-radius: $border-radius--round;
        transition: all $duration ease-in-out;

        svg {
            width: 12px;
            height: 12px;
            margin: 0;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover,
        &:focus {
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }

    .container--nav {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -100%;
            right: 15px;
            z-index: -1;
            height: 100%;
            background-color: $color-white;
            box-shadow: $shadow;
            transition: all ease-in-out $duration;
        }
    }

    .menu__nav__list {
        display: flex;
        width: 100%;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        flex: auto;
        text-align: center;
        transition: all $duration ease-in-out;

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-of-type {
           .nav-main-link {
                &::after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 78px;
            padding: 16px 50px;
            font-size: 1.125rem;
            font-weight: $font-weight-medium;
            line-height: 1.3;
            color: $color-text;
            text-transform: uppercase;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 3px;
                height: 35%;
                background-color: $color-main;
                border-radius: 50px;
                transform: translateY(-50%);
                opacity: 1;
                transition: opacity $duration ease-in-out;
            }

            &:hover, &:focus {
                background: $color-main;
                color: $color-second;
            }
        }

        &:hover, &:focus {
            background: $color-main;
            transition: all $duration ease-in-out;

            .nav-main-link {
                color: $color-second;
            }
            
            + li {
                .nav-main-link {
                    &::after {
                        opacity: 0;
                    }
                }
            }

        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: -50vw;
                right: -50vw;
                background-color: $color-dark--rgba;
            }

            // For Responsive
            &__close {
                display: none;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;
                text-align: left;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid $color-second--rgba;
                    color: $color-white;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 1px;
                        background: $color-white;
                        transition: all $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-second--rgba;
                        transition: all $duration;
                    }

                    &:hover, &:focus {
                        padding-left: 15px;

                        &::before {
                            width: 100%;
                        }
                        &::after {
                            color: $color-white;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 35px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;
                    text-align: left;

                    &::before {
                        display: none;
                    }

                    &::after {
                        right: inherit;
                        left: 10px;
                    }

                    &:hover, &:focus {
                        padding-left: 35px;
                    }
                } 
            }
        }
    }


    // Responsive button
    &__button {
        display: none;
    }

    // Responsive button label
    &__icon {
        position: relative;
        display: none;
        justify-content: flex-start;
        align-items: center;
        column-gap: 15px;
        width: fit-content;
        height: 70px;
        margin: 0;
        padding: 21px 0;
        background-color: transparent;
        font-size: $font-size--4;
        font-weight: $font-weight-bold; 
        color: $color-text;
        text-transform: uppercase;
        transition: $duration;
        cursor: pointer;


        .navicon {
            position: relative;
            top: 0;
            display: block;
            width: 20px;
            height: 4px;
            background: $color-main;
            transition: $duration;

            &::before, 
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-main;
                transition: $duration;
            }

            &::before {
                top: 7px;
            }

            &::after {
                top: -7px;
            } 
        }
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main .container--nav::after {
        right: 10px;
    }

    .nav-main .nav-main-item .nav-main-link {
        padding: 16px 20px;
    }

}


// 960
@media screen and (max-width: $medium) {

    .nav-main {
        position: relative;
        margin-bottom: -35px;
        z-index: inherit;

        .container--nav {
            left: inherit;
            z-index: 15;

            &::after {
                display: none;
            }
        }

        .nav-main-item .sub-menu {
            left: inherit;
        }

        &--active {
            .nav-main__content {
                right: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        
        &__content {
            position: fixed !important;
            top: 0;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 50px;
            transition: $duration;
        }

        &__close {
            display: flex;
        }

        .menu__nav__list {
            flex-direction: column;
            width: 100%;
        }

        .nav-main-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%!important;
            border-bottom: 1px solid rgb(255 255 255 / 30%);

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                color: $color-white;
                transition: all $duration;
            }

            &.menu-item-has-children {

                &:focus-within .sub-menu, 
                &:hover .sub-menu {
                    justify-content: flex-start;
                }

                &.js-active {   
                    .sub-menu {
                        right: 0;
                    }
                }
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
            }

            .nav-main-link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }

            .sub-menu {
                top: 0;
                right: 100%;
                z-index: 1;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                width: 100vw;
                height: 100vh;
                padding: 50px;
                background-color: $color-dark;
                transition: $duration;

                &::after {
                    display: none;
                }

                &__close {
                    display: flex;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                    padding: 0; 
                    overflow-y: auto;
                    
                }

                .nav-child-item, .nav-grandchild-item {
                    margin: auto;

                    a {
                        color: $color-white;

                        &::after {
                            display: none;
                        }
                    }
                    
                }
            }

        }

        // Responsive button label
        .nav-main__icon {
            display: flex;
            position: relative;
            z-index: 15;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: -100vw;
                right: -85px;
                z-index: -1;
                height: 100%;
                background-color: $color-white;
                box-shadow: $shadow;
                transition: all ease-in-out $duration;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .nav-main {
        // Responsive button label
        .nav-main__icon {
            margin: 0 auto;

            &::after {
                right: -100px;
            }
        }
    }

}